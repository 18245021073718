<template>
  <div id="MobileFooter">
    <div class="footer_content">
      <div class="officialStore">
        <div class="title">官方店铺</div>
        <div class="item_name">
          <i class="iconfont icon-tianmaot"></i>
          <span @click="goto(6)" style="padding-left: 0.533vw"
            >让茶天猫旗舰店</span
          >
          <span> | </span>
          <img src="../assets/index/dy2.png" alt="" srcset="" style="width: 2.263vw; height: 2.263vw; vertical-align: -0.233vw;" />
          <span @click="goto(2)" style="padding-left: 0.533vw"
            >让茶抖音旗舰店</span
          >
        </div>
        <div class="item_name">
          <i class="iconfont icon-jingdong1"></i>
          <span @click="goto(3)" style="padding-left: 0.533vw"
            >让茶京东自营旗舰店</span> 
            <span> | </span>
            <i class="iconfont icon-jingdong1"></i>
          <span @click="goto(4)" style="padding-left: 0.533vw"
            >让茶京东旗舰店</span> 
        </div>
       
      </div>
      <div class="concatUs">
        <div class="title">联系我们</div>
        <div class="item_name">
          <i class="iconfont icon-dianhua"></i>
          <!-- <span>  400—807—0177</span> -->
          <a href="tel:400—807—0177">400-807-0177</a>
        </div>
        <div class="item_name">
          <i class="iconfont icon-youxiang"></i>
          <span> hr@rangcha.com</span>
        </div>
      </div>
      <div class="wechat">
        <div class="title">微信公众号</div>
        <div class="content">
          <div class="item">
            <img src="../assets/index/rangcha_qr.jpg" alt="" srcset="" />
            <div>让茶官方</div>
          </div>
            <!-- <div class="item">
              <img src="../assets/index/tasantea_qr.jpg" alt="" srcset="" />
              <div>他山集茶官方</div>
            </div> -->
        </div>
      </div>
    </div>
    <div class="driver"></div>
    <div class="tip" @click="toICP">让茶（北京）饮料有限公司 京ICP备2023008466号-1</div>
  </div>
</template>

<script>
export default {
  name: "MobileFooter",
  components: {},
  props: {},
  setup() {},
  data() {
    return {};
  },
  methods: {
    goto(index) {
      if (index == 1) {
        window.location.href = "https://weibo.com/u/7519768470?is_all=1";
      }
      if (index == 2) {
        window.location.href = "https://www.iesdouyin.com/share/user/MS4wLjABAAAAauaDL6cJp_lzoJv9P9LBmUm0SZNHi11SumEM-FLCibs3_FDYR7k-gLFtF2CHEnkp?iid=MS4wLjABAAAAwbMamZ2Y04pah4uVqO1_QSwThEO1D10OvQ3wxFZc1kxX39J-S95SdjMV5ozgaQKa&life_share_ext=%7B%22life_share_id%22:%220236c224b60c42a3b1acdf35729f1f0e%22,%22enter_from%22:%22others_homepage%22,%22personal_enter_method%22:%22live_cell%22,%22personal_enter_page%22:%22live%22,%22from_user_id%22:75174695652%7D&utm_source=copy&from_ssr=1&sec_uid=MS4wLjABAAAAauaDL6cJp_lzoJv9P9LBmUm0SZNHi11SumEM-FLCibs3_FDYR7k-gLFtF2CHEnkp&tt_from=copy&utm_medium=ios&with_sec_did=1&utm_campaign=client_share&u_code=m1igdj38&from_aid=1128&did=MS4wLjABAAAA2Ppe5kC8A9kjRrrbI3wKzcyW5Ma6eIoLe0z9NrzFrPY&app=aweme&activity_info=%7B%22social_share_time%22:%221742387054%22,%22social_author_id%22:%222808593373987851%22,%22social_share_id%22:%2275174695652_1742387054%22,%22social_share_user_id%22:%2275174695652%22%7D&ecom_share_track_params=%7B%22secuid%22:%22MS4wLjABAAAAQR4Fd0uULtVu8F7Jme99XxdacSKq-jjPA8dVvl2YaSY%22,%22is_ec_shopping%22:%221%22%7D";
      }
      if (index == 3) {
        window.location.href ="https://mall.jd.com/index-1000381582.html?from=pc"
          // "https://shop.m.jd.com/?shopId=1000381582&gx=RnFlwmBeO2aPzdRP--twXVB0_FmP924CG1Pk&ad_od=share&utm_source=androidapp&utm_medium=appshare&utm_campaign=t_335139774&utm_term=CopyURL";
      }
      if (index == 4) {
        window.location.href =
          "https://mall.jd.com/index-16153888.html?from=pc";
      }
      if (index == 5) {
        window.location.href =
          "https://tashanjicha.tmall.com/shop/view_shop.htm";
      }
      if (index == 6) {
        window.location.href = "https://rangcha.m.tmall.com";
      }
    },
    toICP(){
      window.open("https://beian.miit.gov.cn/")
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="less">
#MobileFooter {
  width: 100vw;
  height: 26.4vw;
  background: #383838;
  overflow: hidden;
  .footer_content {
    width: 100vw;
    height: calc(100% - 3.6vw - 1.867vw);
    margin: 1.867vw 1.6vw 0 1.6vw;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .officialStore {
      width: 54vw;
    }
    .concatUs {
      width: 27vw;
    }
    .officialStore,
    .concatUs {
      .title {
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 1.333vw;
      }
      .item_name {
        height: 14px;
        margin-bottom: 0.8vw;
        font-size: 8px;
        font-weight: 400;
        color: #c4c4c4;
        a,
        span {
          font-size: 8px;
          font-weight: 400;
          color: #c4c4c4;
          /* line-height: 2.133vw; */
        }
        & span:nth-child(3) {
          padding: 0 0.8vw;
        }
      }
    }
    .wechat {
      width: 24vw;
      .title {
        font-size: 8px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 2.4vw;
      }
      .content {
        margin-top: 2.4vw;
        .item {
          width: 8.667vw;
          float: left;
          img {
            width: 8.667vw;
            height: 8.667vw;
            margin: 0 1vw;
          }
          div {
            width: 100%;
            text-align: center;
            margin-top: 1.467vw;
            font-size: 6px;
            font-weight: 400;
            color: #c4c4c4;
          }
        }
      }
    }
  }
  .driver {
    width: 100%;
    height: 1px;
    background: #ffff;
    opacity: 0.1;
  }
  .tip {
    width: 100%;
    height: 3.467vw;
    font-size: 7px;
    font-weight: 400;
    color: #ffffff;
    line-height: 3.6vw;
    text-align: center;
    opacity: 0.6;
    cursor: pointer;
  }
}
</style>
